html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  max-width: 700px;
  background: #38648b url("noise.868c4ad1.png");
  margin: 0 auto;
  padding: 25px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
}

body, a {
  color: #fff;
}

h1 {
  letter-spacing: .03em;
  text-align: center;
  margin: 0;
  font-size: 1.4em;
}

p, #location {
  line-height: 1.25;
}

p {
  margin: .8rem 0 .6rem;
}

#location {
  text-align: center;
}

#introduction {
  margin: 25px 0;
}

#introduction p:first-of-type {
  margin-top: 0;
}

#introduction p:last-of-type {
  margin-bottom: 0;
}

h2:not(:focus, :active) {
  clip-path: inset(50%);
  clip: rect(0 0 0 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  position: absolute;
  overflow: hidden;
}

#links ul {
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: center;
  justify-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
}

#links li {
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
}

#links a {
  padding: 10px;
  font-size: .8rem;
  text-decoration: none;
  display: block;
}

#links a:before {
  margin-bottom: .5rem;
  font-size: 1.7rem;
  display: block;
}

#thoughts a:before {
  content: "";
  font-family: "Font Awesome 5 Free";
}

#blog a:before {
  content: "";
  font-family: "Font Awesome 5 Free";
}

#github a:before {
  content: "";
  font-family: "Font Awesome 5 Brands";
}

#gitlab a:before {
  content: "";
  font-family: "Font Awesome 5 Brands";
}

#linkedin a:before {
  content: "";
  font-family: "Font Awesome 5 Brands";
}

#keybase a:before {
  content: "";
  font-family: "Font Awesome 5 Brands";
}

#namedrop a:before {
  content: "";
  font-family: "Font Awesome 5 Free";
}

#email a:before {
  content: "";
  font-family: "Font Awesome 5 Free";
}

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  display: table;
}

main {
  vertical-align: middle;
  display: table-cell;
}

/*# sourceMappingURL=index.01b246a4.css.map */
