/**
 * This is especially helpful when vertically centering the main content, as it
 * allows us to change the main content padding without doing any math.
 *
 * http://www.paulirish.com/2012/box-sizing-border-box-ftw/
 */
/* stylelint-disable rule-empty-line-before */
html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
/* stylelint-enable rule-empty-line-before */

body {
  background: #38648b url("../img/noise.png");
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  margin: 0 auto;
  max-width: 700px;
  padding: 25px;
}

body,
a {
  color: #fff;
}

h1 {
  font-size: 1.4em;
  letter-spacing: 0.03em;
  margin: 0;
  text-align: center;
}

p,
#location {
  line-height: 1.25;
}

p {
  margin: 0.8rem 0 0.6rem;
}

#location {
  text-align: center;
}

#introduction {
  margin: 25px 0;
}

#introduction p:first-of-type {
  margin-top: 0;
}

#introduction p:last-of-type {
  margin-bottom: 0;
}

/**
 * Only show these headings to screen readers.
 *
 * https://css-tricks.com/inclusively-hidden/
 */
h2:not(:focus, :active) {
  clip-path: inset(50%);
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/*** Icons ***/

#links ul {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: center;
  justify-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#links li {
  width: 100%;
  display: block;
  height: 100%;
  text-align: center;
}

#links a {
  display: block;
  font-size: 0.8rem;
  text-decoration: none;
  padding: 10px;
}

#links a::before {
  display: block;
  font-size: 1.7rem;
  margin-bottom: 0.5rem;
}

#thoughts a::before {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 5 Free";

  /**
   * TODO: A thought bubble would be better. Font Awesome 5 doesn't have one,
   * but Font Awesome 6 does.
   */
  content: "\f075";
}

#blog a::before {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 5 Free";
  content: "\f022";
}

#github a::before {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 5 Brands";
  content: "\f09b";
}

#gitlab a::before {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 5 Brands";
  content: "\f296";
}

#linkedin a::before {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 5 Brands";
  content: "\f08c";
}

#keybase a::before {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 5 Brands";
  content: "\f4f5";
}

#namedrop a::before {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 5 Free";
  content: "\f144";
}

#email a::before {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: "Font Awesome 5 Free";
  content: "\f0e0";
}

/*** Vertically center ***/

/* stylelint-disable-next-line no-duplicate-selectors */
html {
  height: 100%;
  width: 100%;
}

/* stylelint-disable-next-line no-duplicate-selectors */
body {
  display: table;
  height: 100%;
}

main {
  display: table-cell;
  vertical-align: middle;
}
